import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetComponent = (props: any) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content={"index,follow"} />
      <meta name="theme-color" content="#000000" />

      {/* Open Graph (OG) meta tags */}
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />

      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />

      {/* Canonical Link */}
      <link rel="canonical" href={window.location.href} />

      {/* Favicon Icons */}
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

      <link rel="apple-touch-icon" href="/favicon.ico" />

      <link rel="icon" sizes="16x16" href="/favicon-16x16.png" />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />

      {/* Apple Touch Icons */}
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="70x70"
        href="/ms-icon-70x70.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="144x144"
        href="/ms-icon-144x144.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="150x150"
        href="/ms-icon-150x150.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="310x310"
        href="/ms-icon-310x310.png"
      />

      {/* Add more meta tags as needed */}
    </Helmet>
  );
};

export default HelmetComponent;
