import React from "react";
import { render } from "react-dom";
import { Toaster } from "react-hot-toast";
import translationEN from "./local/en/translation.json";
import translationENFaq from "./local/en/faq.json";
import translationGR from "./local/gr/translation.json";
import translationGRFaq from "./local/gr/faq.json";
import { HelmetProvider } from "react-helmet-async";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import RoutesList from "./routes";
import "./index.css";

const resources = {
  en: {
    translation: { ...translationEN, ...translationENFaq },
  },
  de: {
    translation: { ...translationGR, ...translationGRFaq },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

render(
  <React.StrictMode>
    <HelmetProvider>
      <RoutesList />
      <Toaster />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
