import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  const navigation = [
    { name: t("footer.ranking"), href: "/" },
    { name: t("footer.terms"), href: "/terms-conditions" },
    { name: t("footer.contact"), href: "/contact-us" },
    { name: t("footer.privacyPolicy"), href: "/privacy-policy" },
    { name: t("footer.cookiePolicy"), href: "/cookie-policy" },
    { name: t("footer.imprint"), href: "/legal-notice" },
  ];

  return (
    <div className="h-[100px] footer-bg px-2 pt-6">
      <div className="flex flex-col items-center justify-center h-full">
        <p className="text-white text-xs">{t("footer.footerContain")}</p>
        <div className="pb-0 text-center">
          {navigation.map((item: any, index: number) => (
            <Link
              key={item.name}
              className="text-white text-xs font-normal pb-2"
              to={item.href}
            >
              {item.name}
              {navigation.length - 1 !== index && <span> | </span>}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
