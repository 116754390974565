import React, { lazy, memo, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RoutesFile from './RouteFile';
import PrivateRoute from './PrivateRoute';
import Loader from '../components/Loader';

const NotFound = lazy(() => import('../container/pageNotFound'));

const RoutesList = memo(() => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          {RoutesFile.map((route: any, idx: number) => {
            if (route.private) {
              return (
                <Route key={idx} path='/' element={<PrivateRoute />}>
                  <Route path={route.path} element={<route.component />}>
                    {/* Render nested routes */}
                    {route.children &&
                      route.children.map((childRoute: any, childIndex: number) => (
                        <Route
                          key={childIndex}
                          path={childRoute.path}
                          element={<childRoute.component />}
                        />
                      ))}
                  </Route>
                </Route>
              );
            } else {
              return (
                <Route key={idx} path={route.path} element={route.component}>
                  {/* Render nested routes */}
                  {route.children &&
                    route.children.map((childRoute: any, childIndex: number) => (
                      <Route
                        key={childIndex}
                        path={childRoute.path}
                        element={<childRoute.component />}
                      />
                    ))}
                </Route>
              );
            }
          })}
          <Route element={<PrivateRoute />}>
            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
});

export default RoutesList;
