/* eslint-disable no-unused-vars */
import React, { ReactNode, useEffect } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import Layout from '../layout';

const PrivateRoute: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    navigate('/')
    // eslint-disable-next-line
  }, [])


  if (location.pathname === '/') return <Navigate to='/ranking' replace />;
  return <Layout>{children}</Layout>;
};


export default PrivateRoute;
