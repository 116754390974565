import HelmetComponent from "../../../src/components/HelmetComponent";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <HelmetComponent
        title={t("seo.404.title")}
        description={t("seo.404.description")}
        keywords={t("seo.404.keywords")}
      />
      <div className="flex justify-center items-center flex-col h-screen overflow-y-auto bg-bgBlack">
        <p className="text-white text-[40px]">404 : Not Found</p>
        <p className="text-white text-sm">
          You are requesting a page that does not exist!
        </p>

        <Link
          to="/privacy-policy"
          className="px-3 py-2 text-primaryGrad0 text-xl border border-primaryGrad0 mt-5 rounded-md"
        >
          Go to home
        </Link>
      </div>
    </>
  );
};

export default PageNotFound;
