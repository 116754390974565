import { lazy } from "react";
import Layout from "../layout";
import PageNotFound from "../container/pageNotFound";

const Ranking = lazy(() => import("../container/ranking"));
const ContactUs = lazy(() => import("../container/contactUs"));
const CookiePolicy = lazy(() => import("../container/cookie-policy"));
const Faq = lazy(() => import("../container/faq"));
const Imprint = lazy(() => import("../container/imprint"));
const PrivacyPolicy = lazy(() => import("../container/privacy-policy"));
const TermsConditions = lazy(() => import("../container/terms-conditions"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "Ranking",
    component: <Layout name={"Ranking"} component={<Ranking />} />,
    private: false,
  },
  {
    path: "/contact-us",
    exact: true,
    name: "Contact-us",
    component: <Layout name={"Contact Us"} component={<ContactUs />} />,
    private: false,
  },
  {
    path: "/cookie-policy",
    exact: true,
    name: "Cookie-policy",
    component: <Layout name={"Cookie Policy"} component={<CookiePolicy />} />,
    private: false,
  },
  {
    path: "/faq",
    exact: true,
    name: "Faq",
    component: <Layout name={"FAQs"} component={<Faq />} />,
    private: false,
  },
  {
    path: "/legal-notice",
    exact: true,
    name: "Legal Notice",
    component: <Layout name={"Imprint"} component={<Imprint />} />,
    private: false,
  },
  {
    path: "/privacy-policy",
    exact: true,
    name: "Privacy-policy",
    component: <Layout name={"Privacy Policy"} component={<PrivacyPolicy />} />,
    private: false,
  },
  {
    path: "/terms-conditions",
    exact: true,
    name: "Terms-condition",
    component: (
      <Layout name={"Terms & Conditions"} component={<TermsConditions />} />
    ),
    private: false,
  },
  {
    path: "*",
    name: "Page Not Found",
    exact: true,
    component: <PageNotFound />,
    private: false,
  },
];
export default routes;
