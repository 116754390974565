import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

const languageOption = ["en", "de"];

const Header = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();

  const navigation = [
    { name: t("header.ranking"), href: "/" },
    { name: t("header.contactUs"), href: "/contact-us" },
    { name: t("header.faq"), href: "/faq" },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const changeLanguage = (item: string) => {
    i18n.changeLanguage(item);
  };

  useEffect(() => {
    if (location.search) {
      const language = location.search.split("?")[1].split("=");
      if (language[0] === "language" && ["en", "de"].includes(language[1])) {
        i18n.changeLanguage(language[1]);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <header className="bg-bgBlack">
      <nav
        className="mx-auto flex  items-center justify-between py-1 px-6"
        aria-label="Global"
      >
        <Link
          className="-m-1.5 p-1.5 text-white font-arial flex items-center font-bold laptop:text-[40px] sm:text-[35px] text-[30px] uppercase"
          to={"/"}
        >
          RICHLIST
        </Link>
        {/* <div className="-m-1.5 p-1.5 text-white font-arial flex items-center font-bold laptop:text-[40px] sm:text-[35px] text-[30px] uppercase">
          RichList
        </div> */}

        <div className="flex laptop:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden laptop:flex laptop:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              className={` text-lg font-normal font-helveticaneue flex items-center uppercase 
                ${
                  location.pathname === item.href
                    ? "text-primaryGrad0"
                    : "text-white"
                }`}
              to={item.href}
            >
              {item.name}
            </Link>
          ))}
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button className="relative flex items-center text-white text-lg font-normal font-helveticaneue uppercase">
                {t("header.language")}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 top-full z-10 mt-2 w-24 border-x-2 border-b-2 border-primaryGrad0">
                {languageOption.map((item: string) => (
                  <Menu.Item key={item}>
                    <div
                      onClick={() => changeLanguage(item)}
                      className={`${
                        i18n.language === item
                          ? "bg-primaryGrad0"
                          : "bg-bgBlack"
                      } block cursor-pointer px-3 py-2 text-sm font-semibold leading-6 text-white`}
                    >
                      {item}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </nav>
      <Dialog
        as="div"
        className="laptop:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-bgBlack px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link
              className="-m-1.5 p-1.5 text-white text-lg font-normal uppercase font-arial"
              to={"/"}
            >
              RichList
            </Link>
            {/* <div className="-m-1.5 p-1.5 text-white text-lg font-normal uppercase font-arial">
              RichList
            </div> */}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    className="-mx-3 block rounded-lg px-3 py-2 font-helveticaneue  leading-7 text-white text-lg font-normal"
                    to={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                ))}
                <Menu as="div" className="relative">
                  <div>
                    <Menu.Button
                      className="relative flex items-center py-2 pb-2 text-white text-lg font-normal font-helveticaneue"
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      {t("header.language")}
                      {menuOpen ? (
                        <ChevronUpIcon
                          className="ml-5 mt-[4px] h-5 w-5 text-white hover:text-violet-100"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-5 mt-[4px] h-5 w-5 text-white hover:text-violet-100"
                          aria-hidden="true"
                        />
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {/* absolute left-0 top-full z-10 mt-2 w-24 border-x-2 border-b-2 border-primaryGrad0 */}
                    <Menu.Items className="absolute left-0 top-full z-10 mt-1 ml-4 w-24 border-x-2 border-b-2 border-primaryGrad0">
                      {languageOption.map((item: any) => (
                        <Menu.Item key={item}>
                          <div
                            onClick={() => {
                              changeLanguage(item);
                              setMobileMenuOpen(false);
                            }}
                            className={`${
                              i18n.language === item
                                ? "bg-primaryGrad0"
                                : "bg-bgBlack"
                            } block cursor-pointer px-3 py-2 text-sm font-semibold leading-6 text-white`}
                          >
                            {item}
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      <div className="h-[10px] bg-gradient-to-r from-primaryGrad0 via-primaryGrad0 to-primaryGrad1"></div>
    </header>
  );
};

export default Header;
