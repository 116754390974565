/* eslint-disable no-unused-vars */
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props: any) => {
  const { component } = props;
  return (
    <div id="main" className="bg-bgBlack">
      <div className="h-full">
        <Header />
        <div className="h-[calc(100vh-80px)] overflow-y-auto">
          <div className="min-h-[calc(100vh-180px)]"> {component}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
